@import url('https://fonts.googleapis.com/css?family=Montserrat');

$ffam: 'Montserrat', sans-serif;
$footer-height: 65px;

html {
  position: relative;
  min-height: 100%;
}
body {
  background-color: $primary-color;
  font-family: $ffam;
  margin-bottom: $footer-height;
  display: block;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
}

body, html {
  height: auto;
}
header {
  background-color: white;
}

.lestuieau-header {

  @include breakpoint(large) {
    @include background-image('le-studieau-large');
  }

  @include breakpoint(medium only) {
    @include background-image('le-studieau');
  }

  @include breakpoint(small only) {
    @include background-image('le-studieau');
  }



  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  display: block;
  box-shadow: none;
  width: 100%;
  height: 500px;
}
.site-logo {
  $logow:650px;
  $logoh:209px;

  @include background-image('le-studieau-logo');
  background-position: center top;
  background-repeat: no-repeat;

  @include breakpoint(large) {
    background-size: $logow $logoh;
    width: $logow;
    height: $logoh;
  }
  @include breakpoint(medium only) {
    background-size: $logow / 2 $logoh / 2;
    width: $logow / 2;
    height: $logoh / 2;

  }
  @include breakpoint(small only)  {
    background-size: $logow / 2 $logoh / 2;
    width: $logow / 2;
    height: $logoh / 2;
  }



  display: block;
  margin: 0 auto;
}

.wave {



  @include breakpoint(large) {
    @include background-image('wave');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: 250px;
    width: 100%;
  }

  @include breakpoint(medium only) {
    @include background-image('wave');
    background-size: 100% 225px;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 225px;
  }

  @include breakpoint(small only) {
    @include background-image('wave');
    background-size: 100% 200px;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 225px;
  }



  display: block;
  margin: 0 auto;


}

.influencer-management {
  @include background-image('influencer-management');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px 120px;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 120px;
}
.graphic-design {
  @include background-image('icon-graphic-design');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 120px;
}
.digital-marketing {
  @include background-image('graphic-design');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px 120px;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 120px;
}
.photography {
  @include background-image('photography');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px 120px;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 120px;
}
.videography {
  @include background-image('videography');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px 120px;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 120px;
}
.web-development {
  @include background-image('web-development');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100px 120px;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 120px;
}
.content-pane {
  font-family: $ffam;
  color: $white;
  p, h1,h2,h3,h4,h5, a {
    font-family: $ffam;
    color: $white;
  }
}
.footer {
  text-align: center;
  background-color: #3b3b3b;
  padding:25px 15px;
  font-size: .8rem;
  color: #ccc;

  a {
    color:#ccc;
  }
}

.content {
  .column {
    text-align: center;
    color: #ffffff;
    font-size: 1.4rem;
  }
}