$image-path: '../img/' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';

@mixin background-image($name, $size:false){
  background-image: url(#{$image-path}#{$name}.svg);
  @if($size){
    background-size: $size;
  }
  .no-svg &{
    background-image: url(#{$image-path}#{$name}.#{$fallback-extension});

    @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
      background-image: url(#{$image-path}#{$name}#{$retina-suffix}.#{$fallback-extension});
    }
  }
}



/*---------------------------------------*/
/* - EMPTY SPACERS */
/*----------------------------------------*/

$empty-spaces: 20;

@for $i from 1 through $empty-spaces {
  .empty-space-#{((100 / $empty-spaces) * $i + 5) * 1} {
    height: ((100 / $empty-spaces) * $i + 5) * 1px;
    display: block;
    clear: both;
  }
}

/*---------------------------------------*/
/*  padding */
/*----------------------------------------*/


$custom-pad: 20;
@for $i from 1 through $custom-pad {
  .cs-pad-#{((100 / $custom-pad) * $i + 5) * 1} {
    padding: ((100 / $custom-pad) * $i + 5) * 1px;
  }
}
